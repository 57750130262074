import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { PaymentBookingComponent } from './payment-booking/payment-booking.component';
import * as momentTz from 'moment-timezone'
import * as moment from 'moment';
import { ActivateLoadingAction, DeactivateLoadingAction } from '../redux/ui.actions';
import { MatCalendar } from '@angular/material/datepicker';
import { PdfModalComponent } from '../home/pdf-modal/pdf-modal.component';
import { AddCardComponent } from '../settings/add-card/add-card.component';
import {  MAT_DATE_LOCALE } from '@angular/material/core';

declare var OpenPay: any

@Component({
  selector: 'app-new-booking',
  templateUrl: './new-booking.component.html',
  styleUrls: ['./new-booking.component.scss'],
  providers: [{ provide: LOCALE_ID, useValue: 'es-ES' },    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' } ]
})
export class NewBookingComponent implements OnInit {

  timeLabel = "Seleccione una hora";
  time_zone = "";
  Today;
  planId = "no_plan";

  doctorId = "";
  doctorCatId = "";
  minDate = new Date();
  doctorTimeSlots = [];
  doctorDate = "";
  doctorTime = "";
  doctorFee = 0;
  cupon = "";
  cuponText = "";
  cuponValid = false;
  totalFee = 0;
  promo = 0;
  dateSelected = false;
  descuento = 0;
  creditValid = false;
  extra_nombre="";
  extra_apellido="";
  totalDates = 0;
  reviewsList = [{ score:0, message:"" }];
  averageReviews = 0;
  dateString = ''

  validatedCoupon = false

  doctor = {
    account_status: "blocked",
    birth_at: 750492000000,
    city: "",
    country: "MX",
    country_code: "+52",
    country_code_flag: "",
    created_at: 1588688193873,
    deleted: false,
    email: "Cargando...",
    email_verified_at: 0,
    first_name: "Cargando...",
    gender: "Male",
    id: 5,
    last_name: "",
    phone: "Cargando...",
    photo_media_id: { url: "http://api.mindhelp.dev/a37d8de0-c122-454b-82e4-df8a480ba9af-1588688385455.png" },
    resume_media_id: { url: "http://api.mindhelp.dev/1656ae56-3d4f-4c13-af6b-63af26501f3d-1588688384869.pdf" },
    roles: [],
    services: [{ name: "", cost: 0 }],
    state: "",
    street_address: "",
    time_slots: [],
    time_zone: "America/Mexico_City",
    updated_at: 1589221054645,
    user_description: "todo bien",
    user_description_short: "",
    working_since:0,
    zip: ""
  }

  showExtras=false;
  calendarDays = [];

  // calendar
  selectedDate = moment();

  //@ViewChild('calendar', { static: true })
  //calendar: MatCalendar<moment.Moment>;
  isDatePicker = false;
  currentMonth = moment().format('MM/DD/YYYY');
  onlyMonth = moment().format('MMMM');

  minDateLimit: Date;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private service: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private renderer: Renderer2) {
      this.setMinDate();
     }

  ngOnInit() {
    moment.locale('es');
    this.checkPromoCode();
    this.getUsesUser();
    this.getCurrentSuscription();
    // agarrar el time zone
    var resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
    this.time_zone = resolvedOptions.timeZone;
    let date = new Date().getTime();

    this.doctorId = this.route.snapshot.paramMap.get("id");
    this.doctorCatId = this.route.snapshot.paramMap.get("cat_id");
    this.getDoctorDetail(this.doctorId);
    this.getReviews();
    this.getTimeSlots();
  }

  setMinDate() {
    const hoy = new Date();
    const mañana = new Date(hoy);
    mañana.setDate(mañana.getDate() + 1); // Suma un día
    this.minDateLimit = mañana;
  }

  ngAfterViewInit(): void {
  }
  /*
  ngDoCheck(): void {
    let c: any = this.calendar;
    let currentMonth = moment(c._clampedActiveDate).format('MM/DD/YYYY');
    if (this.currentMonth != currentMonth) {
      this.onlyMonth = moment(c._clampedActiveDate).format('MMMM');
      console.log(currentMonth, this.currentMonth);
      this.currentMonth = currentMonth;
      this.getTimeSlots();
      //this.highlightDays(this.calendarDays);
      this.isDatePicker = false;
      this.dateSelected = false;
      this.doctorFee = 0;
      this.getDoctorDetail(this.doctorId);
    }
  }
*/
  private highlightDays(days: any[]) {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );
    Array.from(dayElements).forEach((element) => {
      let day = moment(element.getAttribute('aria-label')).format('MM/DD/YYYY');
      const matchingDay = days.find((d) => d === day) !== undefined;
      if (matchingDay) {
        this.renderer.addClass(element, 'available');
        this.renderer.setAttribute(element, 'title', 'Event 1');
        this.renderer.setStyle(element, "background-color","#00c0de")
        this.renderer.setStyle(element, "color","white")
      } else {
        this.renderer.removeClass(element, 'available');
        this.renderer.removeAttribute(element, 'title');
      }
    });
  }

  checkPromoCode(){
    let user = this.service.user;
    console.log(user)
    /*
    if( user.free_promo_code ){
      console.log('USUARIO',user);
      this.cupon = user.free_promo_code.code;
    } else {*/
      console.log('Buscando promo code')
      this.service.getUnusedPromoCodes(user.id).subscribe((data:any) => {
        let decData = JSON.parse(this.service.decrypt(data.message, 'private'));
        console.log('CODIGO', decData)

        if(decData.data.items.length > 0){
          this.cupon = decData.data.items[0].code
        }
      })
    //}
  }

  getTimeSlots() {
    this.store.dispatch(new ActivateLoadingAction());

    let day = moment.utc(this.currentMonth).format('x');
    let id = this.doctorId;
    console.log("Time slots")

    this.service.getHours(id, day, 'month').subscribe((data: any) => {
      let decUser = JSON.parse(this.service.decrypt(data.message, "public"));
      let offset = moment().utcOffset();
      let newDays = decUser.data.items.map(elem => {
        let dayD = elem.day;
        let dayOff = elem.day - (Math.abs(offset) * 60000);
        let dayM = moment(elem.day);
        let dayUtc = moment.utc((elem.day - (Math.abs(offset) * 60000)));
        let dayMOff = moment((elem.day - (Math.abs(offset) * 60000)));
        return moment(elem.day).format("MM/DD/YYYY");//return moment((elem.day - (Math.abs(offset) * 60000))).format("MM/DD/YYYY");//return moment.utc((elem.day - (offset * (60000)))).format("MM/DD/YYYY"); //moment(elem.day).format('MM/DD/YYYY');
      })
      this.calendarDays = newDays;
      this.highlightDays(this.calendarDays);
      this.store.dispatch(new DeactivateLoadingAction());

      this.highlightDays(this.calendarDays);

    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      this.store.dispatch(new DeactivateLoadingAction());
    })
  }

  getDoctorDetail(doc_id) {
    this.service.getDoctorDetail(doc_id, this.doctorCatId).subscribe((data: any) => {
      let decData = JSON.parse(this.service.decrypt(data.message,"public"));
      if(decData.data.items == 0){
        Swal.fire({
          icon: 'error',
          title: 'No Disponible',
          text: "Lo sentimos, este terapeuta no se encuentra disponible por el momento",
          showConfirmButton: true,
          confirmButtonText: "ok",
          confirmButtonColor:'#023171',
        });
        
        this.router.navigate(['/panel/home']);
        return;
      }
      this.doctor = decData.data.items[0];
      console.log('DOCTOR', decData.data.items[0])
      this.totalDates = decData.data.items[0].time_slots.filter(obj =>{
        if(obj.slot_type ==='availability') return true;
        return false;
      }).length; //decData.data.items[0].time_slots.length;
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'No Disponible',
        text: "Lo sentimos, este terapeuta no se encuentra disponible por el momento",
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
      });
      this.router.navigate(['/panel/home']);
      // this.presentAlert(err.error.msg);
    })
  }

  dateChanged() {
    console.log(this.selectedDate)
    console.log("date change", this.selectedDate.format)
    this.isDatePicker = true;
    this.dateSelected = false;
    this.getDate(this.selectedDate);
    this.dateString = moment(this.selectedDate).format('DD-MM-YYYY')
  }

  handleDateChange(selectedDate: any): void {
    this.selectedDate = selectedDate.value
    console.log("date change", this.selectedDate.format)
    this.isDatePicker = true;
    this.dateSelected = false;
    this.getDate(this.selectedDate);
    this.dateString = moment(this.selectedDate).format('DD-MM-YYYY')
    console.log(this.dateString)
  }

  getDate(date) {
    // Get tomorrow's date
    let tomorrow = moment(date).add(0, 'day');
    // Set the time to the current time
    tomorrow.set({ hour: moment().hour(), minute: moment().minute(), second: 0, millisecond: 0 });
    
    // Format the date
    this.doctorDate = tomorrow.format('MM/DD/YYYY');
    
    this.service.getDoctorTimeList(this.doctorId, tomorrow.valueOf()).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message, "public"));
      console.log('DATA 1', decData)
      let slots = this.formatSlots(decData.data.items);
      let newSlots = [];

      // Calcular la fecha y hora dentro de 24 horas
      const dentroDe24Horas = new Date();
      dentroDe24Horas.setHours(dentroDe24Horas.getHours() + 24);
      // Loop through slots
      slots.forEach(slot => {
        if(slot.slot_type === 'availability'){
          if(slot.start > dentroDe24Horas){
            var dateIni = moment(slot.start);
            
            var dateFin = moment().add(30,'minutes');
            if( moment(slot.start).format('DD-MM-YYYY') == tomorrow.format('DD-MM-YYYY')) {
                if(dateIni.isAfter(dateFin))
                    newSlots.push( { start: moment(slot.start).format('HH:mm'), start_hour:moment(slot.start).format('h:mm a'), slot_type:slot.slot_type } );
            }
          }  
        }
      });

      let sortedProducts = newSlots.sort((p1, p2) => (Number(p1.start.split(":")[0]) < Number(p2.start.split(":")[0])) ? -1 : (Number(p1.start.split(":")[0]) > Number(p2.start.split(":")[0])) ? 1 : 0);

      const t = new Date()
      console.log('T',t)
      
      t.setDate(t.getDate() + 1)
      console.log(t.getDate())
      if(date.getDate() === t.getDate()){
        sortedProducts = sortedProducts.filter(slot => {
          const startDate = slot.start
          const horas = t.getHours()
          const minutes = t.getMinutes()
          console.log(`${horas}:${minutes}`)
          return startDate > `${horas}:${minutes}`
        })
      }

      this.service.getDoctorTimeListOtherApi(this.doctorId, tomorrow.valueOf()).subscribe((d:any) => {
        let decData2 = JSON.parse(this.service.decrypt(d.message, "public"));
        console.log('DATA 2', decData2)
        let slots2 = this.formatSlots(decData2.data.items);
        let newSlots2 = [];

        // Calcular la fecha y hora dentro de 24 horas
        const dentroDe24Horas2 = new Date();
        dentroDe24Horas2.setHours(dentroDe24Horas2.getHours() + 24);
        // Loop through slots
        slots2.forEach(slot => {
          if(slot.slot_type === 'availability'){
            if(slot.start > dentroDe24Horas2){
              var dateIni2 = moment(slot.start);
              
              var dateFin2 = moment().add(30,'minutes');
              if( moment(slot.start).format('DD-MM-YYYY') == tomorrow.format('DD-MM-YYYY')) {
                  if(dateIni2.isAfter(dateFin2))
                      newSlots2.push( { start: moment(slot.start).format('HH:mm'), start_hour:moment(slot.start).format('h:mm a'), slot_type:slot.slot_type } );
              }
            }  
          }
        });
        console.log(newSlots,newSlots2)
        let sortedProducts2 = newSlots2.sort((p1, p2) => (Number(p1.start.split(":")[0]) < Number(p2.start.split(":")[0])) ? -1 : (Number(p1.start.split(":")[0]) > Number(p2.start.split(":")[0])) ? 1 : 0);

        
        const t2 = new Date()
        
        t2.setDate(t2.getDate() + 1)
        if(date.getDate() === t2.getDate()){
          sortedProducts2 = sortedProducts2.filter(slot => {
            const startDate = slot.start
            const horas = t2.getHours()
            const minutes = t2.getMinutes()
            console.log(`${horas}:${minutes}`)
            return startDate > `${horas}:${minutes}`
          })
        }
        console.log('SORTED PRODUCTS ',sortedProducts, sortedProducts2)

        const filtered = sortedProducts.filter(element => {
          return sortedProducts2.some(producto2 => (producto2.start === element.start && producto2.slot_type === element.slot_type));
        });

        console.log('FILTRADO',filtered)
        this.doctorTimeSlots = filtered
      })
      
        
      //ACA CHIDO
      // this.doctorTimeSlots = sortedProducts;

      this.timeLabel = "Seleccione una hora";
      if (this.doctorTimeSlots.length == 0) {
          this.timeLabel = "No hay horas disponibles";
      } else if (this.Today != this.doctorDate) {

      }

      let heute = moment(date)
      heute.set({ hour: moment().hour(), minute: moment().minute(), second: 0, millisecond: 0 });
      this.doctorDate = heute.format('MM/DD/YYYY');

    }, err => {
        if(err.name === "HttpErrorResponse"){
            Swal.fire({
                title: 'Error de conexión',
                text: 'Intente de nuevo más tarde.',
                icon: 'error',
                showConfirmButton: true,
                confirmButtonText: "ok",
                confirmButtonColor:'#023171',
            });
        }
        console.log(err)
    });
  }

  compareNumbers(a, b) {
    return a - b;
  }

  monthChanged($event) {
    console.log($event);
  }

  formatSlots(slots){
    let newTimesS = [];
    slots.forEach(element => {
      // get the day of the mont
      let date = moment.utc(element.day ).format('MMM/DD/YYYY');

      // get the times in the day of the mont
      let newTimes = element.availability.map(elem => {

        let start = moment(elem.start_at_miliseconds_utc).format();
        let plushour = moment(elem.start_at_miliseconds_utc).add(1,'hour').format();

        let end =  moment(plushour).format()

        return {
          start: new Date(start),
          minutes: elem.minutes,
          slot_type: elem.slot_type
        }
      })

      newTimesS = newTimesS.concat(newTimes)

    });
    return newTimesS
  }

  getTime(time) {
    console.log(time);
    this.doctorTime = time;
    this.getFee();
  }

  getMoney(doctorFee:number){
    return doctorFee + '.00 MXN'
  }

  getFee() {
    console.log('COSTO', this.doctor.services[0].cost)
    this.doctorFee = this.doctor.services[0].cost;
    this.totalFee = this.doctorFee;
    this.dateSelected = true;
    if( this.planId != "no_plan") {this.applyCupon();}
  }

  applyCupon() {
    this.cuponText = "";
    this.totalFee = this.doctorFee;

    let data = {
      code: this.cupon,
    }

    this.service.applyCupon(data).subscribe((d: any) => {
      let decData = JSON.parse(this.service.decrypt(d.message,"private"));

      console.log(decData.data.items[0])
      let data = decData.data.items[0]
      this.cuponValid = data.isValid;
      if(!this.cuponValid){
        this.resetCupon();
        this.cuponText = "El cupón no se puede aplicar.";
        this.totalFee = this.doctorFee;
        return;
      }
      this.cuponText = "Cupón aplicado";

      this.validatedCoupon = true

      if (data.flat_amount === 0) {
        this.promo = (this.totalFee * data.discount_percentage)
        this.descuento = (this.totalFee * data.discount_percentage)
      } else {
        this.promo = data.flat_amount;
        this.descuento = data.flat_amount;
      }

      console.log(this.promo)

      this.totalFee -= this.promo;

      console.log(this.totalFee)

    }, err => {
      console.log(err)
      if(err.statusText == "Unknown Error" ){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      const decryptedError = this.service.decrypt(err.error.message, 'private')
      console.log(err, decryptedError)
      this.resetCupon();
      this.cuponText = "El cupón no es valido";
      this.cuponValid = false;
      this.totalFee = this.doctorFee;
    })

  }

  applyCredit(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
        this.service.user.uses--;
      }
    },()=>{});
    this.creditValid = true;
    this.totalFee = 0;
  }

  disapplyCredit(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
      }
    },()=>{});
    this.creditValid = false;
    this.totalFee = this.doctorFee;
  }

  saveBooking() {
    if(this.cupon !== '' && this.cuponText !== 'Cupón aplicado'){
      Swal.fire({
        icon: 'warning',
        title: '¡Aplica tu cupón!',
        text: "No olvides aplicar tu cupón antes de seguir con el proceso.",
        showConfirmButton: true,
        confirmButtonText: "ok",
        confirmButtonColor:'#023171',
      });
      return
    }

    console.log(this.totalFee);
    if(this.totalFee <= 0)
      this.suscriptionBooking();
    else
      this.openDialog();

    // return;
    ////if( this.planId != "no_plan" && this.cuponValid ){
    ////this.suscriptionBooking();
    ////}else{
      ////this.openDialog();
    ////}
  }

  openDialog() {
    const dialogRef = this.dialog.open(PaymentBookingComponent, {
      width: '800px',
      height: '600px',
      data: { isSold: false, index: null },
    });

    dialogRef.componentInstance.dataEvent.subscribe((data) => {
      const dialogRefCard = this.dialog.open(AddCardComponent)
      dialogRefCard.afterClosed().subscribe(result => {
        console.log('RESULTADO',result)
        dialogRef.close(result)
      })
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log('RESULT',result);
      this.executePayment(result)
    });
  }

  suscriptionBooking(){
    let time = this.doctorTime+":00";
    let date = this.doctorDate + " " + time;
    console.log(date)

    let start_at = momentTz(date).tz('UTC').format('x');
    console.log(start_at);

    ////let dateplus = moment(date).add(1,'hour').format('LLL');
    ////let end_at = momentTz(dateplus).tz('UTC').format('x');
    let end_at = momentTz(date).add(1,'hour').tz('UTC').format('x');

    let method = this.creditValid ? this.service.PAYMETHODS.creditos:this.service.PAYMETHODS.cupon;

    var data = {
      start_at: parseInt(start_at),
      end_at: parseInt(end_at),
      doctor_user_id: Number(this.doctorId),
      patient_user_id: this.service.user.id,
      base_price: this.doctorFee,
      final_price: this.totalFee,
      review: 5,
      promo_code: this.cupon,
      index: 0,
      paymentmethod_id: method,
      extra_firstname: this.extra_nombre,
      extra_lastname: this.extra_apellido,
      device_session_id:"jjKQM458OJ37A9IrQoxnIUO647e0710h",
      card_token:"ko61eyepcskqsoywobkj"
    }

    if(!this.extra_nombre) delete data.extra_firstname;
    if(!this.extra_apellido) delete data.extra_lastname;

    console.log('CUERPO', data)
    this.service.saveBooking(data).subscribe((d: any) => {
      console.log(d)
      Swal.fire({
        icon: 'success',
        title: '¡Tu sesión ha sido agendada!',
        text: "Muy pronto recibirás confirmación de tu terapeuta",
        showConfirmButton: true,
        confirmButtonText: "ok",
        confirmButtonColor:'#023171',
      });
      this.router.navigate(['/panel/booking']);
    }, err => {
      if(err.name === "HttpErrorResponse"){
        Swal.fire({
          title: 'Error de conexión',
          text: 'Intente de nuevo más tarde.',
          icon: 'error',
          showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        });
      }
      console.log(err)
      let decUser = JSON.parse(this.service.decrypt(err.error.message, "public"));
      console.log(decUser)
      Swal.fire({
        icon: 'error',
        title: 'Ha ocurrido un error',
        showConfirmButton: true,
            confirmButtonText: "ok",
            confirmButtonColor:'#023171',
        text: decUser.error.errors[0].raw.description,
      });
    });
  }

  getReviews(){
    this.service.getReviews(this.doctorId).subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message));
      this.reviewsList = decData.data.items;
      this.reviewsList.forEach(rev => {
        this.averageReviews += rev.score;
      });
      if(this.reviewsList.length > 0)
        this.averageReviews = Math.round(this.averageReviews/this.reviewsList.length);
    })
  }

  getCurrentSuscription(){
    this.service.getCurrentSuscription().subscribe( (d:any) =>{
      let decData = JSON.parse(this.service.decrypt(d.message, "private"));
      console.log('DATOS:',decData.data.items[0]);
      let data = decData.data.items[0];
      this.planId = data.plan.conekta_id;
      this.cupon = data.promo_code.code;
    },err =>{
      let decData = JSON.parse(this.service.decrypt(err.error.message, ""));
      console.log(decData)
      this.planId = "no_plan";
    });
  }

  getUsesUser(){
    this.service.getUsesUser().subscribe((res: any) =>{
      let encData = JSON.parse(this.service.decrypt(res.message, 'private'));
      if(encData.data.items.length > 0){
        this.service.user.max_uses = encData.data.items[0].max_uses;
        this.service.user.uses = encData.data.items[0].uses;
      }
    },()=>{});
  }

  toogleExtras(){
    this.showExtras = !this.showExtras;
  }

  resetCupon(){
    setTimeout(function(){
      this.cuponText = "";
      this.cupon = "";
    },3000);
  }

  setDefaultPic(event){
    event.target.src = './assets/icono.png';
  }

  openPdfModal(link:string): void {
    const dialogRef = this.dialog.open(PdfModalComponent, {
      width: '100%',
      height: '70%',
      position: {
        top:'20%'
      },
      data: {
        pdfLink: link, // Reemplaza con tu enlace web del PDF
      },
    });
  }

  executePayment(result){
    if (result != undefined) {
      let time = this.doctorTime+":00";
      let date = this.doctorDate + " " + time;
      let start_at = momentTz(date).tz('UTC').format('x');
      let fechaLegible = new Date(parseInt(start_at, 10));

      fechaLegible.setHours(fechaLegible.getHours() + 1);
      let end_at = fechaLegible.getTime()

      let method = this.cuponValid ? this.service.PAYMETHODS.tarjetacupon:this.service.PAYMETHODS.tarjeta;
      // return;

      OpenPay.setId('m1coum5skcmrq6pku2yv');
    ​  OpenPay.setApiKey('pk_da9513e220b247fb931f10910924b216');
      //OpenPay.setSandboxMode(true)
      //OpenPay.setSandboxMode(false)

      var deviceDataId = OpenPay.deviceData.setup();

      let data = {
        start_at: start_at,
        end_at: end_at,
        doctor_user_id: Number(this.doctorId),
        patient_user_id: this.service.user.id,
        base_price: this.doctorFee,
        final_price: this.totalFee,
        //final_price:900,
        review: 5,
        promo_code: this.cupon,
        index: 0,
        paymentmethod_id: method,

        device_session_id:deviceDataId,
        card_token:result.id
      }
      console.log(data)

      //this.service.savePayedBooking(body).subscribe((d: any) => {
        this.service.saveBooking(data).subscribe((d: any) => {
          d = JSON.parse(this.service.decrypt(d.message, 'public'))
          console.log('RESPONSE',d)
          window.location.href = d.data.items[0].url;
          
        }, err => {
          console.log(err.error.message)
          let decUser = JSON.parse(this.service.decrypt(err.error.message, "public"));
          console.log(decUser)
          Swal.fire({
            icon: 'error',
            title: 'Ha ocurrido un error',
            showConfirmButton: true,
                confirmButtonText: "ok",
                confirmButtonColor:'#023171',
            text: decUser.error.errors[0].message,
          });
        })
        /*
      }, err => {
        if(err.name === "HttpErrorResponse"){
          Swal.fire({
            title: 'Error de conexión',
            text: 'Intente de nuevo más tarde.',
            icon: 'error',
          });
        }
        let decUser = JSON.parse(this.service.decrypt(err.error.message, "private"));
        console.log('ERROR', decUser)
        Swal.fire({
          icon: 'error',
          title: 'Ha ocurrido un error',
          showConfirmButton: true,
          confirmButtonText: "ok",
          confirmButtonColor:'#023171',
          text: 'Ocurrió un error al realizar el pago. Intente de nuevo más tarde, o revise sus datos.'//err.error.error.errors[0].message,
        });
      });
      */
    }
  }

  formatDate(d){
    let date = new Date(d)
    return moment(date, "YYYYMMDD").fromNow(true);
  }

}
